import {SourceList, SourceProductType} from './declarations';

const EMPTY_GRID = '0000 0000 0000 0000 000';

const SOURCE_PRODUCT_TYPE_TO_LIST: Partial<Record<SourceProductType, SourceList>> = {
  [SourceProductType.Nmdp]: SourceList.Nmdp,

  [SourceProductType.Coop]: SourceList.Coop,

  [SourceProductType.Bmdw]: SourceList.Wmda,

  [SourceProductType.Bmdwd]: SourceList.Wmda,
};

export const sourceProductTypeToList = (productType: SourceProductType): SourceList =>
  SOURCE_PRODUCT_TYPE_TO_LIST[productType];

export function formatGrid(value: string, showEmpty = false): string {
  if (!value) {
    return showEmpty ? EMPTY_GRID : '';
  }

  if (value.length === 19) {
    return `${value.substring(0, 4)} ${value.substring(4, 8)} ${value.substring(8, 12)} ${value.substring(
      12,
      16
    )} ${value.substring(16, 19)}`;
  }

  return value;
}
